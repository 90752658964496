import Choices from 'choices.js'

export const InitChoices = (parentElement?: HTMLElement) => {

  const choicesParent = parentElement ? parentElement : document

  choicesParent.querySelectorAll('select').forEach(select => {
    let choices = new Choices(select, {
      searchEnabled: false,
      itemSelectText: '',
      shouldSort: false,
    })

    // choices select does not have a refresh mode
    select.addEventListener(
      "addedItems",
      () => {
        const items = []

        for (const option of select.options) {
          items.push({ value: option.value, label: option.label })
        }

        choices.setChoices(items, 'value', 'label', true)

        if(!choices.passedElement.element.value) {
          choices.setChoiceByValue("")
        }
      },
      false,
    )

    const behavior = select.dataset['behavior']

    if(behavior) {
      switch (behavior) {
        case 'onchange-submit':
          const formOnChangeSubmit = select.closest('form')
          formOnChangeSubmit && select.addEventListener('change', () => {
            formOnChangeSubmit.submit()
          })
          break
        case 'onchange-request-submit':
          const formOnChangeRequestSubmit = select.closest('form')
          formOnChangeRequestSubmit && select.addEventListener('change', () => {
            formOnChangeRequestSubmit.requestSubmit()
          })
          break
        case 'onchange-redirect':
          select.addEventListener('change', () => {
            // @ts-ignore
            window.location = choices.getValue().value
          })
          break
        case 'onchange-change':

          break
      }
    }
  })
}
